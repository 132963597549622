import './EproSurvey.less'

import { useLocation } from '@gatsbyjs/reach-router'
import { navigate } from 'gatsby-plugin-react-intl'
import React, { useEffect, useReducer, useState } from 'react'

import SurveyUnavailable from '../../../../../assets/images/survey-unavailable.svg'
import { useScopedIntl } from '../../../../../hooks'
import {
  AccountType,
  BackendError,
  Feature,
  FileAnswer,
  QuestionType,
  SaveDeleteAnswerHandlers,
  SurveyAnswer,
  SurveyBlock,
  UiSettings,
  deleteEproFileAnswer,
  deleteEproSurveyAnswer,
  fetchEproConditionalLogic as fetchEproConditionalLogicRequest,
  fetchEproFilesUrls,
  fetchEproSurvey as fetchEproSurveyRequest,
  fetchStudyTranslatedLanguages,
  fetchUiSettings as fetchUiSettingsRequest,
  saveEproSurveyAnswer,
  saveEproSurveyFileAnswer
} from '../../../../../requests'
import { routes } from '../../../../../routes'
import { isAnswerValueEmpty, localeFromPath, proxyMinioToLocalhost } from '../../../../../utils'
import { DatacLoading, DatacMessage } from '../../../../common'
import {
  Survey,
  SurveyContextConsumer,
  SurveyContextProvider,
  SurveyCounts,
  SurveyDataTypes,
  SurveyDetailsInfoItem,
  SurveySaveAnswerFn,
  SurveyThankYou,
  preservedData,
  surveyDataInitialState,
  surveyDataReducer
} from '../../../../shared/Survey'

const ExpiredEproPage: React.FC = () => {
  const eproSurveyIntl = useScopedIntl('studies.epro.survey.expired')
  return (
    <div className="epro-survey-expired">
      <SurveyUnavailable />
      <p>{eproSurveyIntl('label')}</p>
      <p>{eproSurveyIntl('content')}</p>
    </div>
  )
}

export const EproSurvey: React.FC = () => {
  const intl = useScopedIntl('')
  const surveyInt = useScopedIntl('survey')
  const eproSurveyIntl = useScopedIntl('studies.epro.survey')
  const location = useLocation()
  const token = location.hash.slice(1)?.split('?')[0]
  const [loading, setLoading] = useState(true)
  const [isCalculating, setIsCalculating] = useState(false)
  const [isExpired, setIsExpired] = useState(false)
  const [surveyData, surveyDataDispatch] = useReducer(surveyDataReducer, surveyDataInitialState)
  const [initialStep, setInitialStep] = useState<number>(null)
  const [uiSettings, setUiSettings] = useState<UiSettings>(null)
  const currentLanguage = localeFromPath()

  useEffect(() => {
    if (!token) {
      navigate(routes.notFound(AccountType.Subject))
      return
    }

    setLoading(true)

    const currentPreservedData = preservedData()
    if (currentPreservedData) {
      setInitialStep(parseInt(currentPreservedData?.initialStep, 10))
    }

    fetchUiSettings(token)
  }, [token])

  const fetchEproSurvey = (token: string, updateOnlyAnswers?: boolean) => {
    fetchEproSurveyRequest(
      { token },
      {
        onSuccess: survey => {
          surveyDataDispatch({
            type: SurveyDataTypes.SET,
            survey: updateOnlyAnswers ? { ...surveyData, answers: survey.answers } : survey
          })
          fetchEproConditionalLogic(token)
        },
        onNotFound: () => {
          navigate(routes.notFound(AccountType.Subject))
        },
        onExpired: () => {
          setIsExpired(true)
          setLoading(false)
          setIsCalculating(false)
        },
        onRequestError: () => {
          DatacMessage.error(eproSurveyIntl('unknown_error.title'), eproSurveyIntl('unknown_error.content'))
        }
      }
    )
  }

  const fetchEproConditionalLogic = (token: string) => {
    fetchEproConditionalLogicRequest(
      { token },
      {
        onSuccess: conditionalRules => {
          surveyDataDispatch({ type: SurveyDataTypes.SET_CONDITIONAL_RULES, conditionalRules })
          setLoading(false)
          setIsCalculating(false)
        },
        onRequestError: code => DatacMessage.genericError(intl, code)
      }
    )
  }

  const fetchUiSettings = (token: string) => {
    return fetchUiSettingsRequest(
      { token },
      {
        onSuccess: uiSettings => {
          setUiSettings(uiSettings)
          fetchTranslatedLanguages(token)
          fetchEproSurvey(token)
        },
        onNotFound: () => {
          navigate(routes.notFound(AccountType.Subject))
        },
        onRequestError: () => {
          DatacMessage.error(eproSurveyIntl('unknown_error.title'), eproSurveyIntl('unknown_error.content'))
        }
      }
    )
  }

  const fetchTranslatedLanguages = (token: string) => {
    return fetchStudyTranslatedLanguages(
      { token, feature: Feature.Epro },
      {
        onSuccess: languages => setUiSettings(uiSettings => ({ ...uiSettings, languages })),
        onRequestError: () => DatacMessage.error(eproSurveyIntl('unknown_error.title'))
      }
    )
  }

  const detailsBoxes = (surveyCounts: SurveyCounts, questionsConditionFiltered: SurveyBlock[]) => (
    <>
      <SurveyDetailsInfoItem
        icon="helpCircle"
        title={surveyInt('details.information.questions')}
        value={questionsConditionFiltered.length}
        hideOnMobile
        color={uiSettings?.mainColor}
      />
      <SurveyDetailsInfoItem
        icon="checkCircle"
        title={surveyInt('details.information.responses')}
        value={surveyCounts.fulfilled}
        hideOnMobile
        color={uiSettings?.mainColor}
      />
      {uiSettings?.logoUrl && (
        <img src={proxyMinioToLocalhost(uiSettings?.logoUrl)} alt="logo" className="epro-survey__mobile_logo" />
      )}
    </>
  )

  const saveAnswer: SurveySaveAnswerFn = (answer, question) => {
    return new Promise((resolve, reject) => {
      const handlers = {
        onSuccess: (response?: SurveyAnswer) => {
          if (response) {
            surveyDataDispatch({ type: SurveyDataTypes.UPDATE_ANSWER, answer: response })
            if (response.validationMessages.length) {
              reject()
              return
            }
            // if there are any CALCUL questions, reload the survey
            if (surveyData?.blocks.some(block => block.type === QuestionType.Calcul)) {
              setIsCalculating(true)
              fetchEproSurvey(token, true)
            }
          }
          resolve()
        },
        onDeleteSuccess: () => {
          const answerId = surveyData.answers.find((a: SurveyAnswer) => a.questionId === question.id)?.id
          surveyDataDispatch({
            type: SurveyDataTypes.REMOVE_ANSWER,
            id: answerId
          })
          resolve()
        },
        onRequestError: (code: number) => {
          const questionPrefix =
            code && code === BackendError.EPRO_STATUS_PUBLISHED ? 'no_question_error' : 'save_error'

          DatacMessage.error(surveyInt(`answer.${questionPrefix}.title`), surveyInt(`answer.${questionPrefix}.content`))
          reject()
        }
      }

      const uploadFileHandlers = {
        onFileSizeExceeded: () => {
          DatacMessage.warning(intl('file_upload.error.title'), intl('api.9002'))
          reject()
        },
        onWrongFileFormat: () => {
          DatacMessage.warning(intl('file_upload.error.title'), intl('api.9007'))
          reject()
        }
      }

      const commonParams = {
        questionId: question.id,
        recordId: surveyData.recordId,
        language: currentLanguage,
        token
      }

      const deleteFn = (handlers: SaveDeleteAnswerHandlers) => {
        if (question.type === QuestionType.File) {
          const currentAnswer = surveyData.answers.find(a => a.questionId === question.id)
          deleteEproFileAnswer({ fileId: currentAnswer.value[0].id }, handlers, {
            token,
            recordId: surveyData.recordId
          })
        } else {
          deleteEproSurveyAnswer(commonParams, handlers)
        }
      }

      const saveFn = (handlers: SaveDeleteAnswerHandlers) =>
        [QuestionType.File, QuestionType.Lsa].includes(question.type)
          ? saveEproSurveyFileAnswer(
              { ...commonParams, fileId: (answer as FileAnswer[])?.[0].id },
              { ...handlers, ...uploadFileHandlers }
            )
          : saveEproSurveyAnswer({ ...commonParams, answer }, handlers)

      // File only: if we need to replace a file, first delete and then send new one
      if (question.type === QuestionType.File && surveyData.answers.find(a => a.questionId === question.id)?.value) {
        deleteFn({
          onSuccess: () =>
            isAnswerValueEmpty(question.type, answer)
              ? handlers.onDeleteSuccess()
              : saveFn({ onSuccess: handlers.onSuccess, onRequestError: handlers.onRequestError }),
          onRequestError: handlers.onRequestError
        })
        return
      }

      if (isAnswerValueEmpty(question.type, answer)) {
        deleteFn({ onSuccess: handlers.onDeleteSuccess, onRequestError: handlers.onRequestError })
      } else {
        saveFn({ onSuccess: handlers.onSuccess, onRequestError: handlers.onRequestError })
      }
    })
  }

  return (
    <div className="epro-survey" key={token}>
      <DatacLoading isLoading={loading}>
        {isExpired ? (
          <ExpiredEproPage />
        ) : (
          <SurveyContextProvider
            subjectId={surveyData?.subjectId}
            blocks={surveyData?.blocks}
            answers={surveyData?.answers}
            onFileUrlFetch={fetchEproFilesUrls(token)}
            titleLabel={surveyInt('details.name_label')}
            uiSettings={uiSettings}
            token={token}
            recordId={surveyData?.recordId?.toString()}
            isCalculating={isCalculating}
            showProgress
          >
            <SurveyContextConsumer>
              {({ surveyCounts, filteredQuestions }) => {
                const filteredNoCalculs = filteredQuestions.filter(question => question.type !== QuestionType.Calcul)
                return (
                  <Survey
                    name={surveyData?.surveyName}
                    endPages={[{ component: <SurveyThankYou feature={Feature.SubjectRepository} /> }]}
                    onAnswerSave={saveAnswer}
                    detailsBoxes={detailsBoxes(surveyCounts, filteredNoCalculs)}
                    initialStep={initialStep}
                  />
                )
              }}
            </SurveyContextConsumer>
          </SurveyContextProvider>
        )}
      </DatacLoading>
    </div>
  )
}
